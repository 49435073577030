body {
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */

  width: 100vw;
  height: 100vh;
}

.App {
  width: 100vw;
  height: 100vh;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.router {
  display: flex;
  justify-content: space-around;
  width: 1000px;
  margin: 0px auto;
}

.router li {
  cursor: pointer;
}

.router li a:hover {
  color: blue;
}
.router li a:active {
  color: blue;
}

.content {
  width: 1000px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 0px auto;
  height: 80vh;
  margin-top: 30px;
  padding: 30px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
